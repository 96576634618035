import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from '../../hooks';
import { PaginatedItems } from './common.ts';
import { useBankProfile } from '../../contexts/BankProfileContext.tsx';

export interface Account {
	id: number;
	iban: string;
	currency: string;
	ownerName: string;
	productId: number;
	type: string;
	status: string;
	bic: string;
	externalId: never;
	createdAt: string;
	lastUsedAt: never;
	lastChangedAt: string;
	externalData: never[];
	externalReferences: never[];
	balances: Balance[];
	balance: number;
	availableBalance: number;
	reservedAmount: number;
}

export interface Balance {
	amount: number;
	currency: string;
	display: string;
	balanceType: 'SettlementPositionBalance' | 'AvailableBalance';
	referenceDate: never;
	lastChangeDateTime: never;
}

export const ACCOUNTS_PAGE_SIZE = 20;

function useAccounts(
	currentPage: number,
	limit = ACCOUNTS_PAGE_SIZE,
	iban?: string,
	name?: string,
	currency?: string,
	status?: string[],
) {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const offset = currentPage === 1 ? 0 : (currentPage - 1) * limit;

	const fetchAccounts = async (): Promise<PaginatedItems<Account[]>> => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile?.id}/accounts`,
			{
				params: {
					offset,
					limit,
					...(iban && { iban }),
					...(name && { name }),
					...(currency && { currency: currency.toUpperCase() }),
					...(status?.length > 0 && {
						status: status?.map((s) => s.toUpperCase()).join(','),
					}),
				},
				headers: { Authorization: session?.tokenized },
			},
		);

		return response.data;
	};

	return useQuery({
		queryKey: [
			'accounts',
			selectedProfile?.id,
			currentPage,
			limit,
			iban,
			name,
			currency,
			status,
		],
		queryFn: () => fetchAccounts(),
		enabled: !!selectedProfile,
		select: (data) => {
			const items = data?.items?.map((acc) => ({
				...acc,
				availableBalance: acc.availableBalance / 100,
				reservedAmount: acc.reservedAmount / 100,
				balance: acc.balance / 100,
			}));

			return {
				...data,
				items,
			};
		},
	});
}

// function usePagedAccounts(size: number, page: number) {
// 	const fetchAccounts = async (page: number, size: number): Promise<Account[]> => {
// 		const response = await axios.get(
// 			`${import.meta.env.VITE_API_HOST}/dashboard/accounts?page=${page}&size=${size}`,
// 		);
// 		return response.data;
// 	};

// 	return useQuery({
// 		queryKey: ['accounts', page],
// 		queryFn: () => fetchAccounts(page, size),
// 	});
// }

function useAccountStatement(
	type?: 'pdf' | 'xml',
	accountId?: string,
	from?: string,
	to?: string,
) {
	const { session } = useAuth();
	const { selectedProfile } = useBankProfile();

	const fetchAccountStatement = async () => {
		const response = await axios.get(
			`${import.meta.env.VITE_API_HOST}/api/v1/profiles/${selectedProfile.id}/accounts/${accountId}/statement`,
			{
				headers: { Authorization: session?.tokenized },
				responseType: 'blob',
				params: {
					...(from && { from }),
					...(to && { to }),
					...(type && { type }),
				},
			},
		);

		return response.data;
	};

	return useQuery({
		queryKey: ['statement', accountId, from, to],
		queryFn: () => fetchAccountStatement(),
		enabled: false,
		refetchOnWindowFocus: false,
	});
}

export { useAccounts, useAccountStatement };
