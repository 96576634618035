import * as Dialog from '@radix-ui/react-dialog';
import styles from './useBlocker.module.css';
import Button from '../button';
import { useCallback, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import Typography from '../typography/typography';
import { useTranslation } from 'react-i18next';

export const FormBlocker = (props: { when: boolean }) => {
	const { t } = useTranslation(undefined, { keyPrefix: 'blocker' });
	const [open, setOpen] = useState(true);
	let blocker = useBlocker(
		useCallback(
			({ currentLocation, nextLocation }) =>
				(props.when && currentLocation.pathname !== nextLocation.pathname) ||
				(props.when && currentLocation.state !== nextLocation.state),
			[props.when],
		),
	);

	return (
		<Dialog.Root open={blocker.state === 'blocked'}>
			<Dialog.Overlay className={styles.DialogOverlay} />
			<Dialog.Content
				className={styles.DialogContent}
				onCloseAutoFocus={(e) => e.preventDefault()}
				onPointerDownOutside={() => setOpen(!open)}
				onEscapeKeyDown={() => setOpen(!open)}
			>
				<Dialog.Title className={styles.DialogTitle}>
					<Typography level="h2">{t('title')}</Typography>
				</Dialog.Title>
				<Dialog.Content>
					<Typography level="text">{t('description')}</Typography>
				</Dialog.Content>
				<div className={styles.dialogButtonsContainer}>
					<Dialog.Close asChild>
						<Button className={styles.button} variant={'primary'} onClick={blocker.reset}>
							{t('stay')}
						</Button>
					</Dialog.Close>
					<Dialog.Close asChild>
						<Button
							className={styles.button}
							variant={'secondary'}
							onClick={() => {
								blocker.proceed();
							}}
						>
							{t('leave')}
						</Button>
					</Dialog.Close>
				</div>
			</Dialog.Content>
		</Dialog.Root>
	);
};
