import { useRef, useState } from 'react';
import Button from '../button';
import styles from './fileInput.module.css';

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 20MB in bytes

const FileInput = ({
	onFileSelect,
	accept = '*',
	buttonText = 'Choose File',
	size = MAX_FILE_SIZE,
	disabled = false,
}) => {
	const inputRef = useRef(null);

	const [error, setError] = useState<string | null>(null);

	const handleButtonClick = () => {
		setError('');
		inputRef.current?.click();
	};

	const handleFileChange = (event: any) => {
		const file = event.target.files?.[0];
		if (!file) return;

		if (file.size > size) {
			const errorMessage = `File size must be less than ${size}MB. Selected file is ${(file.size / (1024 * 1024)).toFixed(2)}MB`;
			setError(errorMessage);
			event.target.value = ''; // Reset input
			return;
		}

		setError('');
		if (onFileSelect) {
			onFileSelect(file);
		}
	};

	return (
		<div className={styles.container}>
			<input
				ref={inputRef}
				type="file"
				accept={accept}
				onChange={handleFileChange}
				className={styles.input}
			/>
			<Button
				onClick={handleButtonClick}
				type="button"
				variant="secondary"
				className={styles.button}
				disabled={disabled}
			>
				{buttonText}
			</Button>
		</div>
	);
};

export default FileInput;
