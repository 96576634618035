import { Box, ChevronDownIcon, Flex, Table } from '@radix-ui/themes';
import Typography from '../../components/typography/typography';
import AccountsIcon from '../../components/icons/accountIcon.tsx';
import styles from './accountStatement.module.css';
import { Collapsible } from '../../components/collapsible';
import { useEffect, useState } from 'react';
import { CalendarInput, SimpleInput } from '../../components/input';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/loader';
import { useAccountStatement, useAccounts } from '../../api/hooks/useAccounts.ts';
import { useParams } from 'react-router';
import Button from '../../components/button';
import NewSelect from '../../components/select/new-select.tsx';
import { DownloadIcon } from '../../components/icons';
import { SmallSelect } from '../../components/select';
import Pagination from '../../components/pagination';
import Screen from '../../components/screen';
import Card from '../../components/card';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { useBankProfile } from '../../contexts/BankProfileContext.tsx';
import Alert from '../../components/alert';
import {
	ACCOUNT_STATEMENT_PAGE_SIZE,
	AccountHistory,
	useHistory,
} from '../../api/hooks/useHistory.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

const DOWNLOAD_OPTIONS = [
	{ value: 'pdf', label: 'PDF' },
	// { value: 'csv', label: 'CSV' },
	{ value: 'xml', label: 'XML' },
];

const AccountStatement = () => {
	const { t } = useTranslation();
	const SORT_OPTIONS = [
		{ value: 'beneficiary', label: t('accountStatement.smallButtons.sortByBeneficiary') },
		{ value: 'date', label: t('accountStatement.smallButtons.sortByDate') },
	];

	const params = useParams();
	// const latestOperations = useLatestOperations();
	// const account = accounts.data?.find((x: Account) => x.id === params.id);
	const [period, setPeriod] = useState('dates');
	const [sortBy, setSortBy] = useState();
	const [download, setDownload] = useState<'pdf' | 'xml' | undefined>();
	const [currentPage, setCurrentPage] = useState(1);
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const [from, setFrom] = useState<string | undefined>();
	const [to, setTo] = useState<string | undefined>();
	const [beneficiary, setBeneficiary] = useState<string | undefined>();
	const [description, setDescription] = useState<string | undefined>();

	const { selectedProfile } = useBankProfile();
	const accounts = useAccounts(1, 100);
	const statement = useHistory(
		currentPage,
		ACCOUNT_STATEMENT_PAGE_SIZE,
		selectedProfile?.id,
		params.id,
		from,
		to,
		beneficiary,
		description,
	);
	const specificAccount = accounts?.data?.items.find((x) => x.id == params.id);
	const [filtersOpen, setFiltersOpen] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);

	const accountStatement = useAccountStatement(download, specificAccount?.id, from, to);

	interface Filters {
		from: string;
		to: string;
		beneficiary: string;
		description: string;
	}

	const { register, handleSubmit, control, watch } = useForm<Filters>({
		mode: 'onBlur',
		reValidateMode: 'onChange',
	});

	const onSubmit: SubmitHandler<Filters> = (data) => {
		setFrom(data.from);
		setTo(data.to);
		setBeneficiary(data.beneficiary);
		setDescription(data.description);
	};

	useEffect(() => {
		if (accountStatement.isSuccess && isDownloading) {
			const blob = new Blob([accountStatement.data], { type: `application/${download}` });
			const url = window.URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${specificAccount?.iban}.${download}`);

			document.body.appendChild(link);
			link.click();

			link.parentNode?.removeChild(link);
			window.URL.revokeObjectURL(url);
			setIsDownloading(false);
			setDownload(undefined);
		}
	}, [accountStatement.data]);

	useEffect(() => {
		if (download === 'pdf' || download === 'xml') {
			setIsDownloading(true);
			accountStatement.refetch();
		}
	}, [download]);

	const Filters = () => {
		const periodOptions = [
			{ value: 'dates', label: t('accountStatement.filters.byDates') },
			// { value: 'else', label: t('accountStatement.filters.else') },
		];

		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box className={styles.filtersContainer}>
					<NewSelect
						label={t('accountStatement.filters.period')}
						options={periodOptions}
						value={period}
						onChange={setPeriod}
					/>
					<Flex className={styles.gap} direction={isTabletOrMobile ? 'column' : 'row'}>
						<Controller
							name={'from'}
							control={control}
							render={({ field }) => {
								const { ref, ...rest } = field; // removes ref
								return (
									<CalendarInput
										{...rest}
										label={t('accountStatement.filters.from')}
										allowClear
									/>
								);
							}}
						/>
						<Controller
							name={'to'}
							control={control}
							render={({ field }) => {
								const { ref, ...rest } = field; // removes ref
								return (
									<CalendarInput
										{...rest}
										label={t('accountStatement.filters.to')}
										allowClear
									/>
								);
							}}
						/>
					</Flex>

					<SimpleInput
						{...register('beneficiary')}
						label={t('accountStatement.filters.beneficiary')}
					/>
					<SimpleInput
						{...register('description')}
						label={t('accountStatement.filters.payment')}
					/>

					<Button
						variant={'primary'}
						type={'submit'}
						className={clsx(styles.filterButton, isTabletOrMobile && styles.fullWidth)}
					>
						{t('accounts.filters.button')}
					</Button>
				</Box>
			</form>
		);
	};

	const AccountInformation = () => {
		if (accounts.isLoading) {
			return (
				<Flex className={styles.loaderContainer} justify={'center'}>
					<Loader />
				</Flex>
			);
		}

		const AccountItemMobile = () => {
			return (
				<div className={styles.accountContainerMobile}>
					<Typography level="small-text">{t('accountStatement.account')}</Typography>
					<Typography level="text">{specificAccount?.ownerName}</Typography>
					<Typography level="text">{specificAccount?.iban}</Typography>
				</div>
			);
		};

		const AccountItem = () => {
			return (
				<>
					<Typography className={styles.accountTitleContainer} level="h4">
						{t('accountStatement.account')}
					</Typography>
					<Flex className={styles.accountContainer} align={'center'} justify={'start'}>
						<Flex direction={'column'} align={'start'}>
							<Typography level="xsmall-text">
								{t('dashboard.accountInformationCard.accountName')}
							</Typography>
							<Typography level="text">{specificAccount?.ownerName}</Typography>
						</Flex>
						<Flex direction={'column'} align={'start'}>
							<Typography level="xsmall-text">
								{t('dashboard.accountInformationCard.accountNo')}
							</Typography>
							<Typography level="text">{specificAccount?.iban}</Typography>
						</Flex>
						<Flex direction={'column'} align={'start'}>
							<Typography level="xsmall-text">
								{t('dashboard.accountInformationCard.currency')}
							</Typography>
							<Typography level="text">{specificAccount?.currency}</Typography>
						</Flex>
						<Flex direction={'column'} align={'start'}>
							<Typography level="xsmall-text">
								{t('dashboard.accountInformationCard.availableBalance')}
							</Typography>
							<Typography level="text">
								{specificAccount?.availableBalance.toFixed(2)}
							</Typography>
						</Flex>
					</Flex>
				</>
			);
		};

		return (
			<Flex direction={'column'}>
				{isTabletOrMobile ? AccountItemMobile() : AccountItem()}
			</Flex>
		);
	};

	const OperationsList = () => {
		if (statement.isLoading) {
			return (
				<Flex className={styles.loaderContainer} justify={'center'}>
					<Loader />
				</Flex>
			);
		}

		return (
			<Flex className={styles.operationlistContainer} direction={'column'}>
				<Flex justify={'between'} className={styles.selector}>
					<SmallSelect
						options={SORT_OPTIONS}
						value={sortBy}
						onChange={setSortBy}
						triggerIcon={<ChevronDownIcon />}
						placeholder={t('accountStatement.smallButtons.sortBy')}
						className={styles.sortSelector}
					/>
					<Flex align={'center'} gapX={'2'}>
						{isDownloading && <Loader />}
						<SmallSelect
							options={DOWNLOAD_OPTIONS}
							value={download}
							onChange={setDownload}
							triggerIcon={<DownloadIcon />}
							placeholder={t('accountStatement.smallButtons.download')}
							disabled={isDownloading}
						/>
					</Flex>
				</Flex>

				<Box>
					<Table.Root>
						<Table.Header>
							<Table.Row>
								<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
									{t('accountStatement.tableHeaders.date')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
									{t('accountStatement.tableHeaders.sender')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
									{t('accountStatement.tableHeaders.beneficiary')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
									{t('accountStatement.tableHeaders.docNo')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
									{t('accountStatement.tableHeaders.operation')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
									{t('accountStatement.tableHeaders.amount')}
									{` (${specificAccount?.currency})`}
								</Table.ColumnHeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body style={{ fontWeight: 'bold' }}>
							{statement.data?.items.map((x) => (
								<Table.Row key={x.endToEnd}>
									<Table.Cell>{x.date}</Table.Cell>
									<Table.Cell>
										<Flex direction={'column'}>
											<div>{x.payer.name}</div>
											<div>{x.payer.iban}</div>
										</Flex>
									</Table.Cell>
									<Table.Cell>
										<Flex direction={'column'}>
											<div>{x.beneficiary.name}</div>
											<div>{x.beneficiary.iban}</div>
										</Flex>
									</Table.Cell>
									<Table.Cell>{x.documentNumber}</Table.Cell>
									<Table.Cell>{x.details}</Table.Cell>
									<Table.Cell
										style={
											x.direction === 'inward' ? { color: 'green' } : { color: 'black' }
										}
									>
										{x.amount.display}
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table.Root>
				</Box>
				<Flex justify={'center'}>
					<Pagination
						totalCount={statement.data?.pagination?.count}
						currentPage={currentPage}
						pageSize={ACCOUNT_STATEMENT_PAGE_SIZE}
						onPageChange={(page: number) => setCurrentPage(page)}
						className={styles.pagination}
					/>
				</Flex>
			</Flex>
		);
	};

	const OperationsListMobile = () => {
		if (statement.isLoading) {
			return (
				<Flex className={styles.loaderContainer} justify={'center'}>
					<Loader />
				</Flex>
			);
		}

		const ButtonContent = (operation: AccountHistory) => {
			return (
				<Flex
					direction={'row'}
					justify={'between'}
					align={'center'}
					style={{
						width: '100%',
						height: '71px',
						marginLeft: '19px',
						marginRight: '19px',
					}}
				>
					<Flex direction={'column'}>
						<Typography level="text">{operation.beneficiary.name}</Typography>
						<Typography level="small-text">{operation.date}</Typography>
					</Flex>
					<Typography className={styles.amount} level="text">
						{operation.amount.display}
					</Typography>
				</Flex>
			);
		};

		const CollapsibleContent = (operation: AccountHistory) => {
			return (
				<Flex
					direction={'column'}
					justify={'between'}
					gapY={'4'}
					style={{
						width: '100%',
						margin: '17px 12px',
					}}
				>
					<Flex direction={'row'} justify={'start'} gapX={'6'}>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">
								{t('accountStatement.tableHeaders.payer')}
							</Typography>
							<Typography level="text">{operation.beneficiary.name}</Typography>
						</Flex>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">
								{t('accountStatement.tableHeaders.date')}
							</Typography>
							<Typography level="text">{operation.date}</Typography>
						</Flex>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">
								{t('accountStatement.tableHeaders.docNo')}
							</Typography>
							<Typography level="text">{operation.documentNumber}</Typography>
						</Flex>
					</Flex>
					<Flex direction={'row'} justify={'start'} gapX={'5'}>
						<Flex direction={'column'} style={{ width: 'fit-content' }}>
							<Typography level="xsmall-text">
								{t('accountStatement.tableHeaders.amount')}
							</Typography>
							<Typography className={styles.amount} level="text">
								{operation.amount.display}
							</Typography>
						</Flex>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">
								{t('accountStatement.tableHeaders.operation')}
							</Typography>
							<Typography level="text">{operation.details}</Typography>
						</Flex>
					</Flex>
				</Flex>
			);
		};

		return (
			<Box>
				<Flex
					justify={'center'}
					align={'center'}
					gapY={'5'}
					direction={'column'}
					style={{ marginBottom: '22px' }}
				>
					<SmallSelect
						options={SORT_OPTIONS}
						value={sortBy}
						onChange={setSortBy}
						radius={'full'}
						triggerIcon={<ChevronDownIcon />}
						placeholder={t('accountStatement.smallButtons.sortBy')}
						type={'secondary'}
						className={styles.roundButton}
					/>
					<SmallSelect
						options={DOWNLOAD_OPTIONS}
						value={download}
						onChange={setDownload}
						radius={'full'}
						triggerIcon={<ChevronDownIcon />}
						placeholder={t('accountStatement.smallButtons.download')}
						type={'black'}
						className={styles.roundButton}
						disabled={isDownloading}
					/>
				</Flex>
				<Flex direction={'column'}>
					{statement.data?.items.map((x) => (
						<Box
							key={x.endToEnd}
							style={{
								borderTop: '1px solid #DBDBDB',
							}}
						>
							<Collapsible
								className={styles.collapsibleOperation}
								key={x.endToEnd}
								buttonContent={ButtonContent(x)}
								type={'icon'}
							>
								{CollapsibleContent(x)}
							</Collapsible>
						</Box>
					))}
				</Flex>
			</Box>
		);
	};

	const StatementContainer = () => {
		if (statement.isError) {
			return <Alert type="error">{t('common.failedFetching')}</Alert>;
		} else {
			return (
				<>
					<Box className={styles.upper}>
						<AccountInformation />
						<Collapsible
							isOpen={filtersOpen}
							setOpen={setFiltersOpen}
							buttonContent={t('accountStatement.filters.additionalFilters')}
							className={styles.collapsible}
						>
							<Filters />
						</Collapsible>
					</Box>
					<Flex justify={'between'} direction={'column'}>
						{isTabletOrMobile ? <OperationsListMobile /> : <OperationsList />}
					</Flex>
				</>
			);
		}
	};

	return (
		<Screen>
			<Card
				title={t('accountStatement.title')}
				titleIcon={<AccountsIcon />}
				isLoading={accounts.isLoading}
				link="/accounts"
				linkTitle={t('accountStatement.backLink')}
				className={styles.card}
			>
				<StatementContainer />
			</Card>
		</Screen>
	);
};

export default AccountStatement;
