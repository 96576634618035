import { Box, Flex } from '@radix-ui/themes';
import styles from '../operations.module.css';
import OperationsIcon from '../../../components/icons/operationsIcon.tsx';
import Pagination from '../../../components/pagination/index.ts';
import { Collapsible } from '../../../components/collapsible/index.tsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../components/checkbox/index.ts';
import CalendarInput from '../../../components/input/calendar-input.tsx';
import NewSelect from '../../../components/select/new-select.tsx';
import Button from '../../../components/button/index.ts';
import Link from '../../../components/link/index.ts';
import clsx from 'clsx';
import Screen from '../../../components/screen';
import Card from '../../../components/card';
import { useMediaQuery } from 'react-responsive';
import Typography from '../../../components/typography/typography.tsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LoaderContainer } from '../../../components/card/card.tsx';
import Alert from '../../../components/alert';
import { RefreshIcon } from '../../../components/icons/index.ts';
import { Tabs } from '../common.tsx';
import {
	BATCH_LIST_PAGE_SIZE,
	BATCH_STATUS,
	BatchItem,
	useListBatches,
} from '../../../api/hooks/useBatch.ts';
import * as Separator from '@radix-ui/react-separator';
import TransactionsList from '../../orders/batch/transacrtionsList.tsx';
import Switch from '../../../components/switch/index.ts';

const PAYMENT_STATUSES = [
	{ label: 'Executed', value: 'signed' },
	{ label: 'Others', value: 'others' },
];

export enum OPERATION_TYPE {
	SEPA = 'SEPA',
	INTERNATIONAL = 'SWIFT',
}

const OPERATION_TYPE_OPTIONS = [
	{ label: 'SEPA', value: OPERATION_TYPE.SEPA },
	{ label: 'International', value: OPERATION_TYPE.INTERNATIONAL },
];

interface Filters {
	dateFrom: string;
	dateTo: string;
	type: OPERATION_TYPE;
	documentNumber: string;
	status: 'CREATED' | 'SIGNED' | 'FAILED';
}

const BatchOperations = () => {
	const { t } = useTranslation('', { keyPrefix: 'listOfOperations' });
	const [filtersOpen, setFiltersOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const [dateFrom, setDateFrom] = useState<string | undefined>();
	const [dateTo, setDateTo] = useState<string | undefined>();
	const [type, setType] = useState<OPERATION_TYPE | undefined>();
	const [status, setStatus] = useState<string[]>([PAYMENT_STATUSES[0].value]);
	const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUSES[0].value);
	const [unsigned, setUnsigned] = useState<boolean>(true);
	const [signed, setSigned] = useState<boolean>(true);
	const [failed, setFailed] = useState<boolean>(true);
	const [signing, setSigning] = useState<boolean>(true);
	const [initializing, setInitializing] = useState<boolean>(true);

	const { data, isLoading, isError } = useListBatches(
		BATCH_LIST_PAGE_SIZE,
		currentPage,
		type,
		dateFrom,
		dateTo,
		status,
	);

	const { handleSubmit, control, watch } = useForm<Filters>({
		mode: 'onBlur',
		reValidateMode: 'onChange',
	});

	const onSubmit: SubmitHandler<Filters> = (data) => {
		setDateFrom(data.dateFrom);
		setDateTo(data.dateTo);
		setType(data.type);
	};

	useEffect(() => {
		if (type && !watch('type')) {
			// This is needed to be able to clean type dropdown..
		}
	}, [watch('type')]);

	useEffect(() => {
		const arr: string[] = [];
		if (paymentStatus === PAYMENT_STATUSES[1].value) {
			initializing && arr.push('initializing');
			unsigned && arr.push('unsigned');
			signing && arr.push('signing');
			failed && arr.push('failed');

			setStatus(arr);
		} else {
			setStatus([PAYMENT_STATUSES[0].value]);
		}
	}, [paymentStatus, unsigned, signed, failed, signing]);

	const SingleOperationsLink = () => {
		return (
			<Link to={'/operations'}>
				<Flex gapX={'2'} align={'center'} justify={isTabletOrMobile ? 'center' : 'start'}>
					{t('toSingle')}
					<RefreshIcon />
				</Flex>
			</Link>
		);
	};

	const OperationsContainer = () => {
		return (
			<>
				<Box>
					<Flex justify={'between'} direction={isTabletOrMobile ? 'column' : 'row'}>
						{isTabletOrMobile && <SingleOperationsLink />}
						<Switch
							label={t('batchStatuses.title')}
							defaultValue={paymentStatus}
							onChange={setPaymentStatus}
							value={paymentStatus}
							options={PAYMENT_STATUSES}
						/>
						{!isTabletOrMobile && <SingleOperationsLink />}
					</Flex>
					{paymentStatus === PAYMENT_STATUSES[1].value && <OtherStatuses />}
					<div className={clsx(styles.marginTop, styles.marginBottom)}>
						<Collapsible
							isOpen={filtersOpen}
							setOpen={setFiltersOpen}
							buttonContent={t('filters.button')}
							className={clsx(styles.collapsible)}
						>
							<Filters />
						</Collapsible>
					</div>
				</Box>
				{isLoading ? (
					<LoaderContainer />
				) : isError ? (
					<Alert type="error">{t('alerts.listError')}</Alert>
				) : (
					<>
						{isTabletOrMobile ? <OperationListMobile /> : <OperationListDesktop />}
						<Flex justify={'center'}>
							<Pagination
								totalCount={data?.pagination.count}
								currentPage={currentPage}
								pageSize={BATCH_LIST_PAGE_SIZE}
								onPageChange={(page: number) => setCurrentPage(page)}
								className={styles.pagination}
							/>
						</Flex>
					</>
				)}
			</>
		);
	};

	const OperationListDesktop = () => {
		return (
			<Box>
				<div style={{ marginTop: '32px' }}>
					{data.items.map((batch, i) => (
						<>
							<TransactionsList batch={batch} />
							{i < data.items.length - 1 && (
								<Separator.Root
									decorative
									orientation="horizontal"
									className={styles.separator}
								/>
							)}
						</>
					))}
				</div>
			</Box>
		);
	};

	const OperationListMobile = () => {
		const ButtonContent = (batch: BatchItem) => {
			return (
				<Flex
					direction={'row'}
					justify={'between'}
					align={'center'}
					style={{
						width: '100%',
						height: '71px',
						marginLeft: '19px',
						marginRight: '19px',
					}}
				>
					<Flex direction={'column'}>
						{BATCH_STATUS.INITIALIZING === batch.status && (
							<Typography className={styles.createdStatus} level="small-text">
								{batch.status}
							</Typography>
						)}
						{BATCH_STATUS.FAILED === batch.status && (
							<Typography className={styles.failedStatus} level="small-text">
								{batch.status}
							</Typography>
						)}
						{BATCH_STATUS.UNSIGNED === batch.status && (
							<Typography className={styles.unsignedStatus} level="small-text">
								{batch.status}
							</Typography>
						)}
						<Typography level="text">{batch.fileName}</Typography>
						<Typography level="small-text">{batch.uploadedDate}</Typography>
					</Flex>
					<Typography level="text">{batch.totalAmount}</Typography>
				</Flex>
			);
		};

		const CollapsibleContent = (batch: BatchItem) => {
			return (
				<Flex
					direction={'column'}
					justify={'between'}
					gapY={'4'}
					style={{
						width: '100%',
						height: 'fit-content',
						marginLeft: '12px',
						marginTop: '17px',
						marginBottom: '17px',
					}}
				>
					<Flex direction={'row'} justify={'start'} gapX={'6'}>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.status')}</Typography>
							<Typography level="text">{batch.status}</Typography>
						</Flex>
						{/* <Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.docNo')}</Typography>
							<Link className={styles.link} to={`/orders/${batch.uuid}`}>
								{operation.document_number}
							</Link>
						</Flex> */}
						{/* <Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.account')}</Typography>
							<Typography level="text">{operation.account}</Typography>
						</Flex> */}
					</Flex>
					{/* <Flex direction={'row'} justify={'start'} gapX={'5'}>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.type')}</Typography>
							<Typography level="text">{operation.paymentType}</Typography>
						</Flex>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.correspondent')}</Typography>
							<Typography level="text">{operation.beneficiary.accountNumber}</Typography>
						</Flex>
					</Flex> */}
					{/* <Flex direction={'row'} justify={'start'} gapX={'5'}>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.description')}</Typography>
							<Typography level="text">{operation.amount}</Typography>
						</Flex>
					</Flex> */}
				</Flex>
			);
		};

		return (
			<Box style={{ marginTop: '24px' }}>
				<Flex direction={'column'}>
					{data?.items.map((x: BatchItem) => (
						<Box
							key={x.uuid}
							style={{
								borderTop: '1px solid #DBDBDB',
							}}
						>
							<Collapsible
								className={styles.collapsibleOperation}
								key={x.uuid}
								buttonContent={ButtonContent(x)}
								type={'icon'}
							>
								{CollapsibleContent(x)}
							</Collapsible>
						</Box>
					))}
				</Flex>
			</Box>
		);
	};

	const MobileStatuses = () => {
		return (
			<Flex
				direction={'row'}
				align={'center'}
				justify={'center'}
				gapX={'9'}
				style={{
					marginTop: '36px',
				}}
			>
				<Flex direction={'column'} align={'end'} gap={'3'}>
					<Checkbox
						label={t('batchStatuses.initializing')}
						checked={initializing}
						onChange={setInitializing}
					/>
					<Checkbox
						label={t('batchStatuses.unsigned')}
						checked={unsigned}
						onChange={setUnsigned}
					/>
				</Flex>
				<Flex direction={'column'} align={'end'} gap={'3'}>
					<Checkbox
						label={t('batchStatuses.signing')}
						checked={failed}
						onChange={setFailed}
					/>
					<Checkbox
						label={t('batchStatuses.failed')}
						checked={signing}
						onChange={setSigning}
					/>
				</Flex>
			</Flex>
		);
	};

	const DesktopStatuses = () => {
		return (
			<Flex
				direction={'row'}
				gapX={'9'}
				gapY={'4'}
				className={clsx(styles.marginLeft, styles.marginTop)}
				style={{ flexWrap: 'wrap' }}
			>
				<Checkbox
					label={t('batchStatuses.initializing')}
					checked={initializing}
					onChange={setInitializing}
				/>
				<Checkbox
					label={t('batchStatuses.unsigned')}
					checked={unsigned}
					onChange={setUnsigned}
				/>
				<Checkbox
					checked={signing}
					onChange={setSigning}
					label={t('batchStatuses.signing')}
				/>
				<Checkbox
					checked={failed}
					onChange={setFailed}
					label={t('batchStatuses.failed')}
				/>
			</Flex>
		);
	};

	const OtherStatuses = () => {
		return isTabletOrMobile ? <MobileStatuses /> : <DesktopStatuses />;
	};

	const Filters = () => {
		return (
			<form
				onSubmit={handleSubmit(onSubmit)}
				style={{ flexWrap: 'wrap', rowGap: '10px' }}
			>
				<Flex
					direction={'column'}
					className={clsx(
						!isTabletOrMobile && styles.marginLeft,
						styles.marginTop,
						styles.marginBottom,
					)}
					gap={'5'}
				>
					<Flex
						{...(isTabletOrMobile && { justify: 'center' })}
						direction={'row'}
						gapX={'9'}
						style={{ flexWrap: 'wrap' }}
					>
						<Controller
							name={'dateFrom'}
							control={control}
							render={({ field }) => {
								const { ref, ...rest } = field; // removes ref
								return (
									<CalendarInput
										{...rest}
										className={styles.inputWidth}
										label={t('filters.from')}
										allowClear
									/>
								);
							}}
						/>
						<Controller
							name={'dateTo'}
							control={control}
							render={({ field }) => {
								const { ref, ...rest } = field; // removes ref
								return (
									<CalendarInput
										{...rest}
										className={styles.inputWidth}
										label={t('filters.to')}
										allowClear
									/>
								);
							}}
						/>
					</Flex>
					<Flex
						{...(isTabletOrMobile && { justify: 'center' })}
						direction={'row'}
						gapX={'9'}
						style={{ flexWrap: 'wrap' }}
					>
						<Controller
							name={'type'}
							control={control}
							render={({ ...field }) => {
								const { ref, ...rest } = field; // removes ref
								return (
									<NewSelect
										{...rest}
										label={t('filters.type')}
										options={OPERATION_TYPE_OPTIONS}
										className={styles.inputWidth}
										allowClear
									/>
								);
							}}
						/>
					</Flex>
					<Button
						variant={'primary'}
						type={'submit'}
						className={clsx(styles.filterButton, isTabletOrMobile && styles.fullWidth)}
					>
						{t('filters.submit')}
					</Button>
				</Flex>
			</form>
		);
	};

	return (
		<Screen>
			<Card
				title={t('title')}
				titleIcon={<OperationsIcon className={styles.titleIcon} />}
				className={styles.card}
			>
				<OperationsContainer />
			</Card>
		</Screen>
	);
};

export default BatchOperations;
