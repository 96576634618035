import { Box, Flex, Tabs } from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobeIcon, SwapIcon } from '../../components/icons';
import Typography from '../../components/typography/typography';
import styles from './specificOrder.module.css';
import { OperationStatus } from '../../api/hooks/useLatestOperations.ts';
import Alert from '../../components/alert';
import Button from '../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { SigningStatus, TFA } from '../../components/tfa/tfa.tsx';
import * as Separator from '@radix-ui/react-separator';
import { useMediaQuery } from 'react-responsive';
import * as Label from '@radix-ui/react-label';
import { Account, useAccounts } from '../../api/hooks/useAccounts.ts';
import { Transaction } from '../../api/hooks/types/getTransactions.ts';
import { useProofOfPayment } from '../../api/hooks/useTransactions.ts';
import Loader from '../../components/loader/index.ts';
import { useBankProfile } from '../../contexts/BankProfileContext.tsx';

enum PAYMENT_TYPE {
	DOMESTIC = 'DOMESTIC',
	INTERNATIONAL = 'INTERNATIONAL',
	TEMPLATES = 'templates',
	BATCH = 'batch',
}

const DOWNLOADABLE_STATUS = ['executed'];

const SingleOrder = () => {
	const { t } = useTranslation();
	const accounts = useAccounts(1);
	const [account, setAccount] = useState<Account | undefined>(undefined);
	const [operation, setOperation] = useState<Transaction | undefined>(undefined);
	const [signingStatus, setSigningStatus] = useState<SigningStatus | undefined>();
	const [isDownloading, setIsDownloading] = useState(false);
	const location = useLocation();

	const navigate = useNavigate();
	const isTablet = useMediaQuery({ query: '(max-width: 580px)' });
	const proofOfPayment = useProofOfPayment(operation?.id);

	useEffect(() => {
		setOperation(location.state);
	}, [location.state]);

	useEffect(() => {
		const account = accounts.data?.items.find((x) => x.id == operation?.account_id);
		setAccount(account);
	}, [operation]);

	useEffect(() => {
		if (proofOfPayment.isSuccess && isDownloading) {
			const blob = new Blob([proofOfPayment.data], { type: 'application/pdf' });
			const url = window.URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${operation?.document_number}.pdf`);

			document.body.appendChild(link);
			link.click();

			link.parentNode?.removeChild(link);
			window.URL.revokeObjectURL(url);
			setIsDownloading(false);
		}
	}, [proofOfPayment.data]);

	const DataContainer = (props: { header: string; content: string }) => {
		return (
			<Box className={styles.dataContainer}>
				<Typography level="text">{props.header}</Typography>
				<Typography level="small-text">{props.content}</Typography>
			</Box>
		);
	};

	const PaymentData = () => {
		return (
			<Box className={styles.group}>
				<Typography level="h4">{t('orders.createdPayment.paymentData')}</Typography>
				<DataContainer
					header={t('orders.createdPayment.operationNo')}
					content={operation?.document_number}
				/>
				<DataContainer
					header={t('orders.createdPayment.paymentId')}
					content={operation?.skaleet_id}
				/>
				<DataContainer
					header={t('orders.createdPayment.status')}
					content={operation?.status}
				/>
				{account?.ownerName && (
					<DataContainer
						header={t('orders.createdPayment.user')}
						content={account?.ownerName}
					/>
				)}
				<DataContainer
					header={t('orders.createdPayment.creationDate')}
					content={operation?.execution_date}
				/>
			</Box>
		);
	};

	const PayerAndBeneficiary = () => {
		return (
			<Box className={styles.group}>
				<Typography level="h4">
					{t('orders.createdPayment.payerAndBeneficiary.title')}
				</Typography>
				<DataContainer
					header={t('orders.createdPayment.payerAndBeneficiary.product')}
					content={operation?.paymentType}
				/>
				<DataContainer
					header={t('orders.createdPayment.payerAndBeneficiary.payerName')}
					content={account?.ownerName}
				/>

				<DataContainer
					header={t('orders.createdPayment.payerAndBeneficiary.payerAccount')}
					content={operation?.account.iban ?? operation?.account} // For batches, whole account object is returned
				/>
				<DataContainer
					header={t('orders.createdPayment.payerAndBeneficiary.amount')}
					content={operation?.amount.toFixed(2)}
				/>
				<DataContainer
					header={t('orders.createdPayment.payerAndBeneficiary.currency')}
					content={operation?.currency}
				/>
				<DataContainer
					header={t('orders.createdPayment.payerAndBeneficiary.beneficiaryAccount')}
					content={operation?.beneficiary.accountNumber}
				/>
				<DataContainer
					header={t('orders.createdPayment.payerAndBeneficiary.beneficiaryName')}
					content={operation?.beneficiary.name}
				/>
			</Box>
		);
	};

	const AdditionalPaymentData = () => {
		return (
			<Box className={styles.group}>
				<Typography level="h4">
					{t('orders.createdPayment.additionalPaymentData.title')}
				</Typography>
				<DataContainer
					header={t('orders.createdPayment.additionalPaymentData.priority')}
					content={operation?.payment_urgency}
				/>
				<DataContainer
					header={t('orders.createdPayment.additionalPaymentData.description')}
					content={operation?.payment_details}
				/>
				<DataContainer
					header={t('orders.createdPayment.additionalPaymentData.docNo')}
					content={operation?.document_number}
				/>
			</Box>
		);
	};

	const BeneficiaryBankData = () => {
		return (
			<Box className={styles.group}>
				<Typography level="h4">
					{t('orders.createdPayment.beneficiaryBankData.title')}
				</Typography>
				<DataContainer
					header={t('orders.createdPayment.beneficiaryBankData.address')}
					content={operation?.beneficiary.address?.addressLine}
				/>
				<DataContainer
					header={t('orders.createdPayment.beneficiaryBankData.country')}
					content={operation?.beneficiary.address?.country}
				/>
			</Box>
		);
	};

	const UltimateBeneficiaryData = () => {
		return (
			<Box className={styles.group}>
				<Typography level="h4">
					{t('orders.createdPayment.beneficiaryBankData.ultimateBeneficiary.title')}
				</Typography>
				<DataContainer
					header={t('orders.createdPayment.beneficiaryBankData.ultimateBeneficiary.name')}
					content={operation?.ultimate_beneficiary.name}
				/>
				<DataContainer
					header={t(
						'orders.createdPayment.beneficiaryBankData.ultimateBeneficiary.account',
					)}
					content={operation?.ultimate_beneficiary.accountNumber}
				/>
				<DataContainer
					header={t(
						'orders.createdPayment.beneficiaryBankData.ultimateBeneficiary.address',
					)}
					content={operation?.ultimate_beneficiary.address?.addressLine}
				/>
				<DataContainer
					header={t(
						'orders.createdPayment.beneficiaryBankData.ultimateBeneficiary.country',
					)}
					content={operation?.ultimate_beneficiary.address?.country}
				/>
			</Box>
		);
	};

	const getAlerts = () => {
		if (
			[
				OperationStatus.SIGNED,
				OperationStatus.UNSGINED,
				OperationStatus.CREATED,
				OperationStatus.PROCESSING,
			].includes(operation?.status)
		) {
			const getText = () => {
				if (
					operation?.status === OperationStatus.SIGNED ||
					operation?.status === OperationStatus.PROCESSING
				) {
					return t('listOfOperations.specificOperation.alerts.created');
				}
				if (
					operation?.status === OperationStatus.UNSGINED ||
					operation?.status === OperationStatus.CREATED
				) {
					return t('listOfOperations.specificOperation.alerts.unsigned');
				}
			};

			return (
				<Alert type={'success'} className={styles.alert}>
					{getText()}
				</Alert>
			);
		}

		if (operation?.status === OperationStatus.FAILED) {
			return operation?.failingFields?.map((field) => (
				<Alert key={operation.id} type={'warning'} className={styles.alert}>
					{t('listOfOperations.specificOperation.alerts.failed', { field })}
				</Alert>
			));
		}
	};

	const PaymentInformationContainer = () => {
		return (
			<Box>
				<Tabs.Root value={operation?.paymentType}>
					<Tabs.List>
						<Flex direction={'column'} align={'center'}>
							<Tabs.Trigger
								disabled={operation?.paymentType !== PAYMENT_TYPE.DOMESTIC}
								value={PAYMENT_TYPE.DOMESTIC}
							>
								{!isTablet && t('orders.newPayment.tab')} <SwapIcon />
							</Tabs.Trigger>
							{isTablet && (
								<Label.Root
									className={styles.tabLabel}
									htmlFor={t('orders.newPayment.tab')}
								>
									{t('orders.newPayment.tab')}
								</Label.Root>
							)}
						</Flex>
						<Flex direction={'column'} align={'center'}>
							<Tabs.Trigger
								disabled={operation?.paymentType !== PAYMENT_TYPE.INTERNATIONAL}
								value={PAYMENT_TYPE.INTERNATIONAL}
							>
								{!isTablet && t('orders.international.tab')} <GlobeIcon />
							</Tabs.Trigger>
							{isTablet && (
								<Label.Root
									className={styles.tabLabel}
									htmlFor={t('orders.international.tab')}
								>
									{t('orders.international.tab')}
								</Label.Root>
							)}
						</Flex>
						{/* <Flex direction={'column'} align={'center'}>
							<Tabs.Trigger disabled value="templates">
								{!isTablet && t('orders.templates.tab')} <TemplateIcon />
							</Tabs.Trigger>
							{isTablet && (
								<Label.Root
									className={styles.tabLabel}
									htmlFor={t('orders.templates.tab')}
								>
									{t('orders.templates.tab')}
								</Label.Root>
							)}
						</Flex> */}
					</Tabs.List>
					{getAlerts()}

					<Box>
						<Tabs.Content value={PAYMENT_TYPE.NEW}></Tabs.Content>
						<PaymentData />
						<PayerAndBeneficiary />
						<AdditionalPaymentData />
						<BeneficiaryBankData />
						{operation?.ultimate_beneficiary && <UltimateBeneficiaryData />}
						<Tabs.Content value={PAYMENT_TYPE.INTERNATIONAL}></Tabs.Content>

						<Tabs.Content value={PAYMENT_TYPE.TEMPLATES}></Tabs.Content>
					</Box>
				</Tabs.Root>
			</Box>
		);
	};

	const ButtonsSection = () => {
		const { canSign } = useBankProfile();
		return (
			<>
				{operation?.status === OperationStatus.CREATED && canSign && (
					<TFA transactionId={operation?.id} onSign={setSigningStatus} />
				)}
				<Flex align={'start'} direction={'column'} className={styles.buttonsContainer}>
					{signingStatus === SigningStatus.SUCCESS && (
						<Alert type="success">
							{t('listOfOperations.specificOperation.alerts.signed')}
						</Alert>
					)}
					{signingStatus === SigningStatus.ERROR && (
						<Alert type="error">
							{t('listOfOperations.specificOperation.alerts.signingFailed')}
						</Alert>
					)}
					<Flex direction={'row'} gap={'4'}>
						<Button
							className={styles.button}
							variant={'primary'}
							onClick={() => navigate('/orders', { state: operation })}
						>
							{t('listOfOperations.specificOperation.buttons.reinit')}
						</Button>
						<Button
							className={styles.button}
							variant={'secondary'}
							onClick={() => navigate(-1)}
						>
							{t('listOfOperations.specificOperation.buttons.back')}
						</Button>

						{DOWNLOADABLE_STATUS.includes(operation?.status) && (
							<Button
								className={styles.button}
								variant={'secondary'}
								disabled={isDownloading}
								onClick={() => {
									setIsDownloading(true);
									proofOfPayment.refetch();
								}}
							>
								{isDownloading && <Loader />}
								{t('listOfOperations.specificOperation.buttons.download')}
							</Button>
						)}
					</Flex>
				</Flex>
			</>
		);
	};

	return (
		<Flex style={{ maxWidth: '1216px' }} direction={'row'} justify={'between'}>
			<Flex style={{ width: '100%' }} direction={'column'} gap={'5'}>
				<Box
					style={{
						width: '100%',
						background: 'white',
						padding: '33px 24px 35px 43px',
					}}
				>
					<PaymentInformationContainer />
					<Separator.Root
						decorative
						orientation={'horizontal'}
						className={styles.separator}
					/>
					<ButtonsSection />
				</Box>
			</Flex>
		</Flex>
	);
};

export default SingleOrder;
