import { ReactNode } from 'react';
import { useAuth } from '../hooks';

const basePath =
	import.meta.env.VITE_ENVIRONMENT === 'production'
		? import.meta.env.VITE_ORY_BASE_PATH
		: 'http://localhost:4000';

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
	const { session, error } = useAuth();

	if (!session) {
		if (error?.status === 403) {
			window.location.replace(`${basePath}/ui/login`);
		}
		if (error?.status === 401) {
			window.location.replace(`${basePath}/ui/login`);
		}
		return null;
	} else {
		return children;
	}
};

export default ProtectedRoute;
