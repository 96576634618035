import { Box, Flex, Table, Skeleton } from '@radix-ui/themes';
import styles from './transactionsList.module.css';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Collapsible } from '../../../components/collapsible';
import * as Separator from '@radix-ui/react-separator';
import {
	BATCH_TRANSACTIONS_PAGE_SIZE,
	BatchItem,
	ListBatchTransactionsResponse,
	useListBatchTransactions,
} from '../../../api/hooks/useBatch';
import Pagination from '../../../components/pagination';
import { useEffect, useState } from 'react';
import { TFA } from '../../../components/tfa/tfa';
import { useBankProfile } from '../../../contexts/BankProfileContext';
import Link from '../../../components/link';

const TransactionsList = ({
	batch,
}: {
	batch: BatchItem | ListBatchTransactionsResponse;
}) => {
	const { t } = useTranslation(undefined, { keyPrefix: 'orders.batch.fileUploaded' });
	const { canSign } = useBankProfile();

	const OperationsTable = () => {
		const [currentPage, setCurrentPage] = useState(1);
		const [transactionsCount, setTransactionsCount] = useState(1);

		const transactions = useListBatchTransactions(
			batch.uuid,
			BATCH_TRANSACTIONS_PAGE_SIZE,
			currentPage,
		);

		useEffect(() => {
			if (transactions.isSuccess) {
				setTransactionsCount(transactions.data.pagination.count);
			}
		}, [transactions.isSuccess]);

		const TableHeader = () => {
			return (
				<Table.Header>
					<Table.Row>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('paymentsHeader.date')}
						</Table.ColumnHeaderCell>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('paymentsHeader.docNo')}
						</Table.ColumnHeaderCell>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('paymentsHeader.status')}
						</Table.ColumnHeaderCell>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('paymentsHeader.operation')}
						</Table.ColumnHeaderCell>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('paymentsHeader.account')}
						</Table.ColumnHeaderCell>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('paymentsHeader.amount')}
						</Table.ColumnHeaderCell>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('paymentsHeader.beneficiaryName')}
						</Table.ColumnHeaderCell>
					</Table.Row>
				</Table.Header>
			);
		};

		const TableBodySkeleton = () => {
			return Array.from({ length: 3 }, (_, i) => (
				<Table.Row key={i}>
					<Table.Cell className={styles.tableCell}>
						<Skeleton />
					</Table.Cell>
					<Table.Cell className={styles.tableCell}>
						<Skeleton />
					</Table.Cell>
					<Table.Cell className={styles.tableCell}>
						<Skeleton />
					</Table.Cell>
					<Table.Cell className={styles.tableCell}>
						<Skeleton />
					</Table.Cell>
					<Table.Cell className={styles.tableCell}>
						<Skeleton />
					</Table.Cell>
					<Table.Cell className={styles.tableCell}>
						<Skeleton />
					</Table.Cell>
					<Table.Cell className={styles.tableCell}>
						<Skeleton />
					</Table.Cell>
				</Table.Row>
			));
		};

		return (
			<>
				<Separator.Root
					decorative
					orientation={'horizontal'}
					className={styles.separator}
				/>
				<Table.Root>
					<TableHeader />
					<Table.Body style={{ fontWeight: 'bold' }}>
						<>
							{transactions.isLoading && <TableBodySkeleton />}
							{transactions.isSuccess &&
								transactions.data.items.map((x) => (
									<Table.Row key={x.id}>
										<Table.Cell className={styles.tableCell}>
											{x.execution_date}
										</Table.Cell>
										<Table.Cell className={styles.tableCell}>
											{x.status.toLocaleLowerCase() === 'executed' ? (
												<Link className={styles.link} to={`/orders/${x.id}`} state={x}>
													{x.document_number}
												</Link>
											) : (
												x.document_number
											)}
										</Table.Cell>
										<Table.Cell className={styles.tableCell}>
											{x.status.toString().toUpperCase()}
										</Table.Cell>
										<Table.Cell className={styles.tableCell}>{x.paymentType}</Table.Cell>
										<Table.Cell className={styles.tableCell}>{x.account.iban}</Table.Cell>
										<Table.Cell className={styles.tableCell}>
											{x.amount.toFixed(2)}
										</Table.Cell>
										<Table.Cell className={styles.tableCell}>
											{x.beneficiary.name}
										</Table.Cell>
									</Table.Row>
								))}
						</>
					</Table.Body>
				</Table.Root>
				<Flex justify={'center'}>
					<Pagination
						totalCount={transactionsCount}
						currentPage={currentPage}
						pageSize={BATCH_TRANSACTIONS_PAGE_SIZE}
						onPageChange={(page: number) => setCurrentPage(page)}
					/>
				</Flex>
				{batch.status === 'UNSIGNED' && canSign && (
					<div style={{ marginBottom: '14px', marginTop: '22px' }}>
						<TFA transactionId={batch.uuid} onSign={() => {}} type="batch" />
					</div>
				)}
			</>
		);
	};

	const BatchTable = () => {
		return (
			<Table.Root style={{ width: '1000px' }}>
				<Table.Header>
					<Table.Row>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('batchHeader.fileName')}
						</Table.ColumnHeaderCell>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('batchHeader.status')}
						</Table.ColumnHeaderCell>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('batchHeader.date')}
						</Table.ColumnHeaderCell>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('batchHeader.count')}
						</Table.ColumnHeaderCell>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('batchHeader.errorsCount')}
						</Table.ColumnHeaderCell>
						<Table.ColumnHeaderCell className={styles.operationsTableHeaderCell}>
							{t('batchHeader.total')}
						</Table.ColumnHeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body style={{ fontWeight: 'bold' }}>
					<Table.Row>
						<Table.Cell style={{ width: '20%' }}>{batch.fileName}</Table.Cell>
						<Table.Cell style={{ width: '15%' }} className={clsx(styles.underscore)}>
							{batch.status}
						</Table.Cell>
						<Table.Cell>{batch.uploadedDate || batch.uploadDate}</Table.Cell>
						<Table.Cell>{batch.operationCount || batch.operationsCount}</Table.Cell>
						<Table.Cell>{batch.errorCount}</Table.Cell>
						<Table.Cell>{batch.totalAmount.toFixed(2)}</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table.Root>
		);
	};

	const ExpandableBatch = () => {
		return (
			<Collapsible
				type="icon"
				buttonContent={
					<>
						<BatchTable />
					</>
				}
				// className={styles.collapsibleBatch}
			>
				<OperationsTable />
			</Collapsible>
		);
	};

	return (
		<Box>
			{/* <ExpandableAccountList fromAccount={fromAccount} setFromAccount={setFromAccount} /> */}
			{/* <BatchTable /> */}
			<ExpandableBatch />
		</Box>
	);
};

export default TransactionsList;
