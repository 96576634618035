import * as RadioGroup from '@radix-ui/react-radio-group';
import { Flex } from '@radix-ui/themes';
import styles from './scrollable-accounts-list.module.css';
import Typography from '../../components/typography/typography.tsx';
import { useTranslation } from 'react-i18next';
import ScrollArea from '../../components/scroll-area';
import { useMediaQuery } from 'react-responsive';
import { Account } from '../../api/hooks/useAccounts.ts';
import Pagination from '../../components/pagination/index.ts';

interface ScrollableAccountsProps {
	accounts?: Account[];
	onValueChanged: (e: any) => void;
	selectedAccount?: Account;
	disabledAccount?: Account;
	currentPage: number;
	pageSize: number;
	totalCount: number;
	onPageChange: (page: number) => void;
}

const ScrollableAccountsList = ({
	accounts = [],
	onValueChanged,
	selectedAccount,
	disabledAccount,
	currentPage,
	pageSize,
	totalCount,
	onPageChange,
}: ScrollableAccountsProps) => {
	const { t } = useTranslation();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });

	const DesktopList = (acc: Account) => {
		return (
			<>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text" fontWeight="400">
						{t('dashboard.accountInformationCard.accountName')}
					</Typography>
					<Typography level="h4">{acc.ownerName}</Typography>
				</Flex>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text" fontWeight="400">
						{t('dashboard.accountInformationCard.accountNo')}
					</Typography>
					<Typography level="h4">{acc.iban}</Typography>
				</Flex>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text" fontWeight="400">
						{t('dashboard.accountInformationCard.currency')}
					</Typography>
					<Typography level="h4">{acc.currency}</Typography>
				</Flex>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text" fontWeight="400">
						{t('dashboard.accountInformationCard.balance')}
					</Typography>
					<Typography level="h4">{acc?.availableBalance.toFixed(2)}</Typography>
				</Flex>
			</>
		);
	};

	const MobileList = (acc: Account) => {
		return (
			<Flex
				direction={'row'}
				justify={'between'}
				align={'center'}
				style={{
					width: '100%',
					height: '71px',
				}}
			>
				<Flex direction={'column'}>
					<Typography level="small-text">{t('accounts.item.headers.name')}</Typography>
					<Typography level="h4">{acc.ownerName}</Typography>
					<Typography level="small-text">{t('accounts.item.headers.number')}</Typography>
					<Typography level="h4">{acc.iban}</Typography>
				</Flex>
				<Flex direction={'column'}>
					<Typography level="small-text">
						{t('accounts.item.headers.currency')}
					</Typography>
					<Typography level="h4">{acc.currency}</Typography>
				</Flex>
			</Flex>
		);
	};

	return (
		<ScrollArea>
			<RadioGroup.Root
				defaultValue={selectedAccount?.id}
				key={selectedAccount?.id}
				onValueChange={onValueChanged}
				className={styles.radioGroup}
			>
				{accounts?.map((acc) => (
					<RadioGroup.Item
						disabled={acc.id === disabledAccount?.id}
						className={styles.item}
						key={acc.id}
						value={acc.id}
						style={{ width: 'inherit' }}
					>
						<Flex
							className={styles.accountList}
							key={acc.iban}
							justify={'between'}
							align={'center'}
							style={{ width: '100%' }}
						>
							{isTabletOrMobile ? MobileList(acc) : DesktopList(acc)}
						</Flex>
					</RadioGroup.Item>
				))}
				<Flex justify={'center'}>
					<Pagination
						totalCount={totalCount}
						currentPage={currentPage}
						pageSize={pageSize}
						onPageChange={onPageChange}
						className={styles.pagination}
					/>
				</Flex>
			</RadioGroup.Root>
		</ScrollArea>
	);
};

export default ScrollableAccountsList;
