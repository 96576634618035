import { Box, Flex, Table } from '@radix-ui/themes';
import styles from './operations.module.css';
import OperationsIcon from '../../components/icons/operationsIcon.tsx';
import Pagination from '../../components/pagination/index.ts';
import { Collapsible } from '../../components/collapsible/index.tsx';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '../../components/switch/index.ts';
import Checkbox from '../../components/checkbox/index.ts';
import CalendarInput from '../../components/input/calendar-input.tsx';
import NewSelect from '../../components/select/new-select.tsx';
import SimpleInput from '../../components/input/simple-input.tsx';
import Button from '../../components/button/index.ts';
import Link from '../../components/link/index.ts';
import clsx from 'clsx';
import Screen from '../../components/screen';
import Card from '../../components/card';
import { useMediaQuery } from 'react-responsive';
import Typography from '../../components/typography/typography.tsx';
import { useBankProfile } from '../../contexts/BankProfileContext.tsx';
import {
	STATEMENTS_PAGE_SIZE,
	useTransactions,
} from '../../api/hooks/useTransactions.ts';
import { OPERATION_STATUS, Transaction } from '../../api/hooks/types/getTransactions.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LoaderContainer } from '../../components/card/card.tsx';
import Alert from '../../components/alert';
import { RefreshIcon } from '../../components/icons/index.ts';
import { OperationStatus } from '../../api/hooks/useLatestOperations.ts';
import * as RadioGroup from '../../components/radio-button/radio-button.tsx';
import { SigningStatus, TFA } from '../../components/tfa/tfa.tsx';

export enum OPERATION_TYPE {
	SEPA = 'DOMESTIC',
	INTERNATIONAL = 'INTERNATIONAL',
}

const OPERATION_TYPE_OPTIONS = [
	{ label: 'SEPA', value: OPERATION_TYPE.SEPA },
	{ label: 'International', value: OPERATION_TYPE.INTERNATIONAL },
];

interface Filters {
	dateFrom: string;
	dateTo: string;
	type: OPERATION_TYPE;
	documentNumber: string;
	status: 'CREATED' | 'SIGNED' | 'FAILED';
	senderIban: string;
	beneficiaryIban: string;
	beneficiaryName: string;
}

const Operations = () => {
	const { t } = useTranslation('', { keyPrefix: 'listOfOperations' });
	const PAYMENT_STATUSES = [
		{ label: t('executed'), value: 'executed' },
		{ label: t('others'), value: 'others' },
	];

	const [filtersOpen, setFiltersOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	// const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUSES[0].value);
	// const [operationType, setOperationType] = useState(OPERATION_TYPE_OPTIONS[0].value);
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });
	const { selectedProfile } = useBankProfile();
	const [dateFrom, setDateFrom] = useState<string | undefined>();
	const [dateTo, setDateTo] = useState<string | undefined>();
	const [type, setType] = useState<OPERATION_TYPE | undefined>();
	const [status, setStatus] = useState<string[]>([PAYMENT_STATUSES[0].value]);
	const [documentNumber, setDocumentNumber] = useState<string | undefined>();
	const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUSES[0].value);
	const [unsigned, setUnsigned] = useState<boolean>(true);
	const [signed, setSigned] = useState<boolean>(true);
	const [failed, setFailed] = useState<boolean>(true);
	const [processed, setProcessed] = useState<boolean>(true);
	const [senderIban, setSenderIban] = useState<string | undefined>();
	const [beneficiaryIban, setBeneficiaryIban] = useState<string | undefined>();
	const [beneficiaryName, setBeneficiaryName] = useState<string | undefined>();

	const { data, isLoading, isError } = useTransactions(
		selectedProfile?.id,
		currentPage,
		STATEMENTS_PAGE_SIZE,
		dateFrom,
		dateTo,
		type,
		documentNumber,
		status,
		senderIban,
		beneficiaryIban,
		beneficiaryName,
	);

	const { register, handleSubmit, control, watch, getValues } = useForm<Filters>({
		mode: 'onBlur',
		reValidateMode: 'onChange',
	});

	const onSubmit: SubmitHandler<Filters> = (data) => {
		setDateFrom(data.dateFrom);
		setDateTo(data.dateTo);
		setType(data.type);
		setDocumentNumber(data.documentNumber);
		setSenderIban(data.senderIban);
		setBeneficiaryIban(data.beneficiaryIban);
		setBeneficiaryName(data.beneficiaryName);
	};

	useEffect(() => {
		if (type && !watch('type')) {
			// This is needed to be able to clean type dropdown..
		}
	}, [watch('type')]);

	useEffect(() => {
		const arr: string[] = [];
		if (paymentStatus === PAYMENT_STATUSES[1].value) {
			unsigned && arr.push('created');
			signed && arr.push('signed');
			failed && arr.push('failed');
			processed && arr.push('processed');

			setStatus(arr);
		} else {
			setStatus([PAYMENT_STATUSES[0].value]);
		}
	}, [paymentStatus, unsigned, signed, failed, processed]);

	const SingleOperationsLink = () => {
		return (
			<Link to={'batch'}>
				<Flex gapX={'2'} align={'center'} justify={isTabletOrMobile ? 'center' : 'start'}>
					{t('toBatch')}
					<RefreshIcon />
				</Flex>
			</Link>
		);
	};

	const OperationsContainer = () => {
		return (
			<>
				<Box>
					<Flex justify={'between'} direction={isTabletOrMobile ? 'column' : 'row'}>
						{isTabletOrMobile && <SingleOperationsLink />}
						<Tabs />
						{!isTabletOrMobile && <SingleOperationsLink />}
					</Flex>
					{paymentStatus === PAYMENT_STATUSES[1].value && <OtherStatuses />}
					<div className={clsx(styles.marginTop, styles.marginBottom)}>
						<Collapsible
							isOpen={filtersOpen}
							setOpen={setFiltersOpen}
							buttonContent={t('filters.button')}
							className={clsx(styles.collapsible)}
						>
							<Filters />
						</Collapsible>
					</div>
				</Box>
				{isLoading ? (
					<LoaderContainer />
				) : isError ? (
					<Alert type="error">{t('common.failedFetching')}</Alert>
				) : (
					<>
						{isTabletOrMobile ? <OperationListMobile /> : <OperationListDesktop />}
						<Flex justify={'center'}>
							<Pagination
								totalCount={data?.pagination.count}
								currentPage={currentPage}
								pageSize={STATEMENTS_PAGE_SIZE}
								onPageChange={(page: number) => setCurrentPage(page)}
								className={styles.pagination}
							/>
						</Flex>
					</>
				)}
			</>
		);
	};

	const OperationListDesktop = () => {
		const [selectedOperation, setSelectedOperation] = useState<string>();
		const { canSign } = useBankProfile();

		const showCheckbox = (status: OperationStatus) =>
			paymentStatus === PAYMENT_STATUSES[1].value && status === OPERATION_STATUS.CREATED;

		const afterSign = (status: SigningStatus) => {
			if (status === SigningStatus.CANCELED) {
				setSelectedOperation(undefined);
			} else {
				setTimeout(() => {
					setSelectedOperation(undefined);
				}, 5000);
			}
		};

		return (
			<Box>
				<RadioGroup.Root
					className={styles.RadioGroupRoot}
					onValueChange={setSelectedOperation}
					value={selectedOperation}
				>
					<Table.Root>
						<Table.Header>
							<Table.Row>
								<Table.ColumnHeaderCell className={styles.tableHeader} />

								<Table.ColumnHeaderCell className={styles.tableHeader}>
									{t('table.date')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.tableHeader}>
									{t('table.docNo')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.tableHeader}>
									{t('table.status')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.tableHeader}>
									{t('table.recipientAccount')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.tableHeader}>
									{t('table.amount')}
								</Table.ColumnHeaderCell>
								<Table.ColumnHeaderCell className={styles.tableHeader}>
									{t('table.name')}
								</Table.ColumnHeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body style={{ fontWeight: 'bold' }}>
							{data?.items.map((x) => (
								<Table.Row key={x.id}>
									{showCheckbox(x.status) ? (
										<Table.Cell>
											<RadioGroup.Item
												className={styles.RadioGroupItem}
												value={x.id}
												id="r1"
											>
												<RadioGroup.Indicator className={styles.RadioGroupIndicator} />
											</RadioGroup.Item>
										</Table.Cell>
									) : (
										<Table.Cell />
									)}
									<Table.Cell maxWidth={'136px'}>{x.execution_date}</Table.Cell>
									<Table.Cell style={{ textWrap: 'wrap' }} maxWidth={'160px'}>
										<Link className={styles.link} to={`/orders/${x.id}`} state={x}>
											{x.document_number}
										</Link>
									</Table.Cell>
									<Table.Cell>{x.status}</Table.Cell>
									<Table.Cell>{x.beneficiary.accountNumber}</Table.Cell>
									<Table.Cell>{x.amount.toFixed(2)}</Table.Cell>
									<Table.Cell maxWidth={'270px'} style={{ textWrap: 'wrap' }}>
										{x.beneficiary.name}
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table.Root>
				</RadioGroup.Root>
				{canSign && selectedOperation && (
					<div style={{ marginTop: '14px' }}>
						<TFA transactionId={selectedOperation} onSign={afterSign} />
					</div>
				)}
			</Box>
		);
	};

	const OperationListMobile = () => {
		const ButtonContent = (operation: Transaction) => {
			return (
				<Flex
					direction={'row'}
					justify={'between'}
					align={'center'}
					style={{
						width: '100%',
						height: '71px',
						marginLeft: '19px',
						marginRight: '19px',
					}}
				>
					<Flex direction={'column'}>
						{OPERATION_STATUS.CREATED === operation.status && (
							<Typography className={styles.createdStatus} level="small-text">
								{operation.status}
							</Typography>
						)}
						{OPERATION_STATUS.FAILED === operation.status && (
							<Typography className={styles.failedStatus} level="small-text">
								{operation.status}
							</Typography>
						)}
						{OPERATION_STATUS.UNSIGNED === operation.status && (
							<Typography className={styles.unsignedStatus} level="small-text">
								{operation.status}
							</Typography>
						)}
						<Typography level="text">{operation.beneficiary.name}</Typography>
						<Typography level="small-text">{operation.execution_date}</Typography>
					</Flex>
					<Typography level="text">{operation.amount}</Typography>
				</Flex>
			);
		};

		const CollapsibleContent = (operation: Transaction) => {
			return (
				<Flex
					direction={'column'}
					justify={'between'}
					gapY={'4'}
					style={{
						width: '100%',
						height: 'fit-content',
						marginLeft: '12px',
						marginTop: '17px',
						marginBottom: '17px',
					}}
				>
					<Flex direction={'row'} justify={'start'} gapX={'6'}>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.status')}</Typography>
							<Typography level="text">{operation.status}</Typography>
						</Flex>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.docNo')}</Typography>
							<Link
								className={styles.link}
								to={`/orders/${operation.id}`}
								state={operation}
							>
								{operation.document_number}
							</Link>
						</Flex>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.account')}</Typography>
							<Typography level="text">{operation.account}</Typography>
						</Flex>
					</Flex>
					<Flex direction={'row'} justify={'start'} gapX={'5'}>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.type')}</Typography>
							<Typography level="text">{operation.paymentType}</Typography>
						</Flex>
						{/*<Flex direction={'column'}>*/}
						{/*	<Typography level="xsmall-text">Operation No.</Typography>*/}
						{/*	<Typography level="text">{operation.}</Typography>*/}
						{/*</Flex>*/}
						<Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.correspondent')}</Typography>
							<Typography level="text">{operation.beneficiary.accountNumber}</Typography>
						</Flex>
					</Flex>
					<Flex direction={'row'} justify={'start'} gapX={'5'}>
						<Flex direction={'column'}>
							<Typography level="xsmall-text">{t('table.description')}</Typography>
							<Typography level="text">{operation.amount}</Typography>
						</Flex>
					</Flex>
				</Flex>
			);
		};

		return (
			<Box style={{ marginTop: '24px' }}>
				<Flex direction={'column'}>
					{data?.items.map((x) => (
						<Box
							key={x.id}
							style={{
								borderTop: '1px solid #DBDBDB',
							}}
						>
							<Collapsible
								className={styles.collapsibleOperation}
								key={x.id}
								buttonContent={ButtonContent(x)}
								type={'icon'}
							>
								{CollapsibleContent(x)}
							</Collapsible>
						</Box>
					))}
				</Flex>
			</Box>
		);
	};

	const MobileStatuses = () => {
		return (
			<Flex
				direction={'row'}
				align={'center'}
				justify={'center'}
				gapX={'9'}
				style={{
					marginTop: '36px',
				}}
			>
				<Flex direction={'column'} align={'end'} gap={'3'}>
					<Checkbox
						label={t('paymentStatuses.unsigned')}
						checked={unsigned}
						onChange={setUnsigned}
					/>
					<Checkbox
						label={t('paymentStatuses.failed')}
						checked={failed}
						onChange={setFailed}
					/>
				</Flex>
				<Flex direction={'column'} align={'end'} gap={'3'}>
					<Checkbox
						label={t('paymentStatuses.signed')}
						checked={signed}
						onChange={setSigned}
					/>
					<Checkbox
						label={t('paymentStatuses.processed')}
						checked={processed}
						onChange={setProcessed}
					/>
				</Flex>
			</Flex>
		);
	};

	const DesktopStatuses = () => {
		return (
			<Flex
				direction={'row'}
				gapX={'9'}
				gapY={'4'}
				className={clsx(styles.marginLeft, styles.marginTop)}
				style={{ flexWrap: 'wrap' }}
			>
				<Checkbox
					label={t('paymentStatuses.unsigned')}
					checked={unsigned}
					onChange={setUnsigned}
				/>
				<Checkbox
					checked={signed}
					onChange={setSigned}
					label={t('paymentStatuses.signed')}
				/>
				<Checkbox
					checked={failed}
					onChange={setFailed}
					label={t('paymentStatuses.failed')}
				/>
				<Checkbox
					checked={processed}
					onChange={setProcessed}
					label={t('paymentStatuses.processed')}
				/>
			</Flex>
		);
	};

	const OtherStatuses = () => {
		return isTabletOrMobile ? <MobileStatuses /> : <DesktopStatuses />;
	};

	const today = new Date();

	const beforeDate = useCallback(() => {
		return getValues('dateFrom');
	}, [watch('dateFrom')]);

	const Filters = () => {
		return (
			<form
				onSubmit={handleSubmit(onSubmit)}
				style={{ flexWrap: 'wrap', rowGap: '10px' }}
			>
				<Flex
					direction={'column'}
					className={clsx(
						!isTabletOrMobile && styles.marginLeft,
						styles.marginTop,
						styles.marginBottom,
					)}
					gap={'5'}
				>
					<Flex
						{...(isTabletOrMobile && { justify: 'center' })}
						direction={'row'}
						gapX={'9'}
						style={{ flexWrap: 'wrap' }}
					>
						<Controller
							name={'dateFrom'}
							control={control}
							render={({ field }) => {
								const { ref, ...rest } = field; // removes ref
								return (
									<CalendarInput
										{...rest}
										className={styles.inputWidth}
										label={t('filters.from')}
										allowClear
									/>
								);
							}}
						/>
						<Controller
							name={'dateTo'}
							control={control}
							render={({ field }) => {
								const { ref, ...rest } = field; // removes ref
								return (
									<CalendarInput
										{...rest}
										className={styles.inputWidth}
										label={t('filters.to')}
										allowClear
										disabledDays={{ before: beforeDate() }}
									/>
								);
							}}
						/>
					</Flex>
					<Flex
						{...(isTabletOrMobile && { justify: 'center' })}
						direction={'row'}
						gapX={'9'}
						style={{ flexWrap: 'wrap' }}
					>
						<Controller
							name={'type'}
							control={control}
							render={({ ...field }) => {
								const { ref, ...rest } = field; // removes ref
								return (
									<NewSelect
										{...rest}
										label={t('filters.type')}
										options={OPERATION_TYPE_OPTIONS}
										className={styles.inputWidth}
										allowClear
									/>
								);
							}}
						/>
						<SimpleInput
							{...register('documentNumber')}
							label={t('filters.docNo')}
							className={styles.inputWidth}
						/>
					</Flex>
					<Flex
						{...(isTabletOrMobile && { justify: 'center' })}
						direction={'row'}
						gapX={'9'}
						style={{ flexWrap: 'wrap' }}
					>
						<SimpleInput
							{...register('senderIban')}
							label={t('filters.senderIban')}
							className={styles.inputWidth}
						/>
						<SimpleInput
							{...register('beneficiaryIban')}
							label={t('filters.beneficiaryIban')}
							className={styles.inputWidth}
						/>
					</Flex>
					<Flex
						{...(isTabletOrMobile && { justify: 'center' })}
						direction={'row'}
						gapX={'9'}
						style={{ flexWrap: 'wrap' }}
					>
						<SimpleInput
							{...register('beneficiaryName')}
							label={t('filters.beneficiaryName')}
							className={styles.inputWidth}
						/>
					</Flex>
					<Button
						variant={'primary'}
						type={'submit'}
						className={clsx(styles.filterButton, isTabletOrMobile && styles.fullWidth)}
					>
						{t('filters.submit')}
					</Button>
				</Flex>
			</form>
		);
	};

	const Tabs = () => {
		return (
			<Flex justify={isTabletOrMobile ? 'center' : 'start'}>
				<Switch
					options={PAYMENT_STATUSES}
					value={paymentStatus}
					onChange={setPaymentStatus}
					label={t('statusSwitch')}
				/>
			</Flex>
		);
	};

	return (
		<Screen>
			<Card
				title={t('title')}
				titleIcon={<OperationsIcon className={styles.titleIcon} />}
				className={styles.card}
				// isLoading={isLoading}
			>
				<OperationsContainer />
			</Card>
		</Screen>
	);
};

export default Operations;
