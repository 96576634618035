import { Configuration, FrontendApi, Session } from '@ory/client';
import {
	createContext,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { removeSelectedProfile } from './BankProfileContext';
import { AxiosError } from 'axios';

interface AuthContextType {
	logoutUrl?: string;
	error?: any;
	session: Session | undefined;
	logout: () => void;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const basePath =
	import.meta.env.VITE_ENVIRONMENT === 'production'
		? import.meta.env.VITE_ORY_BASE_PATH
		: 'http://localhost:4000';

const ory = new FrontendApi(
	new Configuration({
		basePath,
		baseOptions: {
			withCredentials: true,
		},
	}),
);

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
	const [logoutUrl, setLogoutUrl] = useState<string>();
	const [error, setError] = useState<AxiosError>();
	const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
	const [session, setSession] = useState<Session | undefined>();
	const tokenRefreshTimeout = useRef<number | null>(null);

	useEffect(() => {
		refreshSession();
	}, []);

	async function setupTokenRefresh() {
		if (tokenRefreshTimeout.current) {
			clearTimeout(tokenRefreshTimeout.current);
		}
		// Calculate time until refresh (10 minutes before expiry)
		const date = new Date();
		const expiryTime = new Date(date.setMinutes(date.getMinutes() + 15)).getTime(); // Currently JWT expires after 15 minutes
		const currentTime = Date.now();
		const refreshThreshold = 10 * 60 * 1000; // 10 minutes
		const timeUntilRefresh = expiryTime - currentTime - refreshThreshold;

		if (timeUntilRefresh <= 0) {
			await refreshSession();
			return;
		}

		tokenRefreshTimeout.current = setTimeout(async () => {
			await refreshSession();
		}, timeUntilRefresh);
	}

	const refreshSession = async () => {
		console.log('refreshSession');

		try {
			const { data } = await ory.toSession({
				tokenizeAs: import.meta.env.VITE_ORY_JWT_TEMPLATE_NAME,
			});

			if (data) {
				setSession(data);
				setupTokenRefresh();
			}

			const logoutFlow = await ory.createBrowserLogoutFlow();
			console.log('setting logout url');

			setLogoutUrl(logoutFlow.data.logout_url);
		} catch (err: any) {
			setError(err);
			console.log(err);
			console.log('No session.', err.message);
		} finally {
			setLoadingInitial(false);
		}
	};

	const logout = useCallback(async () => {
		console.log('logout before session refresh');
		await refreshSession();
		console.log('logout after session refresh');

		if (logoutUrl) {
			console.log('logoutUrl', logoutUrl);
			removeSelectedProfile();
			window.location.replace(logoutUrl);
		}
	}, [logoutUrl]);

	const memoedValue = useMemo(
		() => ({
			error,
			session,
			logout,
		}),
		[error, session, logout],
	);

	return (
		<AuthContext.Provider value={memoedValue}>
			{!loadingInitial && children}
		</AuthContext.Provider>
	);
}
