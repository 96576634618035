import { Flex } from '@radix-ui/themes';
import { forwardRef } from 'react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import styles from './checkbox.module.css';

interface CheckboxProps extends RadixCheckbox.CheckboxProps {
	label: string;
	className?: string;
	onChange?: (e: boolean) => void;
	isError?: boolean;
	checked?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
	({ label, className, onChange, isError, checked, ...props }, forwardRef) => {
		return (
			<Flex align={'center'} ref={forwardRef} className={className}>
				<label className={styles.Label} htmlFor={label.toLowerCase()}>
					{label}
				</label>
				<RadixCheckbox.Root
					className={styles.CheckboxRoot}
					id={label.toLowerCase()}
					checked={checked || props.field?.value}
					onCheckedChange={onChange || props.field?.onChange}
					onClick={(e) => e.stopPropagation()}
					// {...props}
				>
					<RadixCheckbox.Indicator className={styles.CheckboxIndicator}>
						<CheckIcon />
					</RadixCheckbox.Indicator>
				</RadixCheckbox.Root>
			</Flex>
		);
	},
);

Checkbox.displayName = 'Checkbox';
export default Checkbox;
