import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SepaPayment } from '../../../api/hooks/types/createTransaction';
import {
	useCreateTransaction,
	useDocumentNumber,
	useSignTransaction,
} from '../../../api/hooks/useTransactions';
import styles from './mainData.module.css';
import { useAccounts } from '../../../api/hooks';
import { Collapsible } from '../../../components/collapsible';
import { ScrollableAccountsList } from '../../../blocks/scrollable-accounts-list';
import { useEffect, useMemo, useState } from 'react';
import { Account } from '../../../api/hooks/useAccounts';
import Button from '../../../components/button';
import { CalendarInput, InputDropdown, SimpleInput } from '../../../components/input';
import { useTranslation } from 'react-i18next';
import Typography from '../../../components/typography/typography';
import Switch from '../../../components/switch';
import { Flex, TextArea } from '@radix-ui/themes';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import AdditionalDataForm from './additionalData';
import { format } from 'date-fns';
import { useValidateIBAN } from '../../../api/hooks/useUtilities';
import Loader from '../../../components/loader';
import { TFA, SigningStatus } from '../../../components/tfa/tfa';
import { useGeoDataContext } from '../../../contexts/GeoDataContext';
import { FormBlocker } from '../../../components/blocker/use-blocker';
import Alert from '../../../components/alert';
import { useBankProfile } from '../../../contexts/BankProfileContext';

const ACCOUNTS_PAGE_SIZE = 10;

const DEFAULT_VALUES = {
	currency: 'EUR',
	payment_details: '',
	payment_type: 'DOMESTIC',
	payment_urgency: 'NORMAL',
	execution_date: format(new Date(), 'yyyy-MM-dd'),
	end_to_end: '',
	beneficiary: {
		name: '',
		account: '',
		id: {
			type: undefined,
			value: '',
		},
		address: {
			type: 'STRUCTURED',
			country: undefined,
			line: '',
			town: '',
			building_number: '',
			postal_code: '',
		},
	},
	ultimate_beneficiary: {
		account: '',
		name: '',
		id: {
			type: undefined,
			value: '',
		},
		address: {
			type: 'STRUCTURED',
			country: undefined,
			line: '',
			town: '',
			building_number: '',
			postal_code: '',
		},
	},
	payer: {
		type: undefined,
		value: '',
	},
	primary_payer: {
		id: {
			type: undefined,
			value: '',
		},
	},
} as Partial<SepaPayment>;

const SepaPaymentForm = ({ operation }: { operation: any }) => {
	const [fromAccount, setFromAccount] = useState<Account>();
	const documentNumber = useDocumentNumber(fromAccount?.id);
	const { canSign } = useBankProfile();
	const [currentPage, setCurrentPage] = useState(1);
	const [toSelfCurrentPage, setToSelfCurrentPage] = useState(1);

	const {
		setError,
		register,
		handleSubmit,
		formState,
		control,
		watch,
		setValue,
		reset,
		resetField,
		clearErrors,
	} = useForm<SepaPayment>({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		defaultValues: DEFAULT_VALUES,
		values: {
			...DEFAULT_VALUES,
			account_id: fromAccount?.id || undefined,
			document_number: undefined,

			...(operation && {
				amount: operation.amount,
				currency: operation.currency,
				payment_details: operation.payment_details,
				beneficiary: {
					name: operation.beneficiary.name,
					account: operation.beneficiary.accountNumber,
					...(operation.beneficiary.address && {
						address: {
							type: 'STRUCTURED',
							country: operation.beneficiary.address?.country,
							line: operation.beneficiary.address?.addressLine,
							postal_code: operation.beneficiary.address?.postalCode,
							town: operation.beneficiary.address?.townName,
							building_number: operation.beneficiary.address?.buildingNumber,
						},
					}),
				},
				...(operation.ultimate_beneficiary && {
					ultimate_beneficiary: {
						name: operation.ultimate_beneficiary?.name,
						account: operation.ultimate_beneficiary?.accountNumber,
						...(operation.ultimate_beneficiary.address && {
							address: {
								type: 'STRUCTURED',
								country: operation.ultimate_beneficiary.address?.country,
								line: operation.ultimate_beneficiary.address?.addressLine,
								postal_code: operation.ultimate_beneficiary.address?.postalCode,
								town: operation.ultimate_beneficiary.address?.townName,
								building_number: operation.ultimate_beneficiary.address?.buildingNumber,
							},
						}),
					},
				}),
			}),
		},
	});

	const { t } = useTranslation();

	const createTransaction = useCreateTransaction();
	const accounts = useAccounts(
		currentPage,
		ACCOUNTS_PAGE_SIZE,
		undefined,
		undefined,
		undefined,
		['OPENED', 'CREDIT_BLOCKED'],
	);
	const toSelfAccounts = useAccounts(
		toSelfCurrentPage,
		ACCOUNTS_PAGE_SIZE,
		undefined,
		undefined,
		undefined,
		['OPENED', 'CREDIT_BLOCKED'],
	);

	const [toSelf, setToSelf] = useState(false);
	const [isOpenAccounts, setOpenAccounts] = useState(false);
	const [isOpenSelf, setOpenSelf] = useState(false);
	const [selfAccount, setSelfAccount] = useState<Account>();
	const [transferInfoType, setTransferInfoType] = useState('description');
	const [filtersOpen, setFiltersOpen] = useState(false);
	const [signingStatus, setSigningStatus] = useState<SigningStatus | undefined>();
	const [createOrderResponse, setCreateOrderResponse] = useState<{
		isError: boolean;
		data: any;
	}>({
		isError: false,
		data: undefined,
	});
	const [ibanToValidate, setIbanToValidate] = useState<string | undefined>(undefined);
	const validateIBAN = useValidateIBAN(ibanToValidate);
	const geoData = useGeoDataContext();

	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 480px)' });

	const TRANSFER_INFO_TYPES = [
		{
			value: 'description',
			label: t('orders.newPayment.data.transferType.description'),
		},
		{ value: 'refNo', label: t('orders.newPayment.data.transferType.refNo') },
	];

	const onIbanBlur = async (event: any) => {
		const { value } = event.target;
		setIbanToValidate(value);

		if (value) {
			setIbanToValidate(value);
		}
	};

	useEffect(() => {
		if (ibanToValidate) {
			validateIBAN.refetch();
		}
	}, [ibanToValidate]);

	useEffect(() => {
		if (!validateIBAN.data) return;

		if (!validateIBAN.data?.isValid) {
			setError('beneficiary.account', {
				message: t('orders.newPayment.form.accountNoError'),
			});
		} else {
			clearErrors('beneficiary.account');
		}
	}, [validateIBAN.data, validateIBAN.isFetching]);

	const errors = useMemo(() => formState.errors, [formState]);

	const today = new Date();
	const yearAfter = () => {
		const futureDate = new Date();
		futureDate.setFullYear(futureDate.getFullYear() + 1);
		return futureDate;
	};

	const onSubmit: SubmitHandler<SepaPayment> = (data) => {
		createTransaction.mutate({ data });
	};

	useEffect(() => {
		if (documentNumber.isSuccess) {
			setValue('document_number', documentNumber.data?.documentNumber);
		}
	}, [documentNumber.data]);

	useEffect(() => {
		if (operation) {
			setFromAccount(accounts.data?.items.find((x) => x.id == operation.account_id));
		} else {
			setFromAccount(accounts.data?.items[0]);
		}
	}, [accounts.data]);

	useEffect(() => {
		if (toSelf && selfAccount) {
			setValue('beneficiary.name', selfAccount.ownerName);
			setValue('beneficiary.account', selfAccount.iban);
		} else {
			if (operation) {
				setValue('beneficiary.name', operation.beneficiary.name);
				setValue('beneficiary.account', operation.beneficiary.accountNumber);
			} else {
				setValue('beneficiary.name', '');
				setValue('beneficiary.account', '');
			}
		}
	}, [toSelf, selfAccount]);

	useEffect(() => {
		handleAccountChange();
	}, [fromAccount]);

	const handleAccountChange = () => {
		const acc = toSelfAccounts.data?.items.find((x) => x.id !== fromAccount?.id);
		if (!acc) {
			setToSelfCurrentPage(1);
		} else {
			setSelfAccount(acc);
		}
	};

	useEffect(() => {
		if (toSelfAccounts.isFetched && toSelf && fromAccount?.id === selfAccount?.id) {
			handleAccountChange();
		}
	}, [toSelfCurrentPage]);

	const resetForm = (status: SigningStatus) => {
		reset(undefined, { keepDefaultValues: true, keepDirty: true });
		setCreateOrderResponse({ isError: false, data: undefined });
		setSigningStatus(status);
		setIbanToValidate(undefined);
		setToSelf(false);
	};

	useEffect(() => {
		if (!createTransaction.data) return;
		setCreateOrderResponse({
			isError: false,
			data: createTransaction.data,
		});
	}, [createTransaction.data]);

	useEffect(() => {
		if (createTransaction.isError) {
			setCreateOrderResponse({
				isError: true,
				data: createTransaction?.error?.response?.data,
			});
		}
	}, [createTransaction.isError]);

	const AccountInformation = (account: Account) => {
		return (
			<Flex
				justify={'between'}
				align={'center'}
				style={{
					width: '592px',
					height: '66px',
					background: 'white',
					cursor: 'pointer',
				}}
			>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text" fontWeight="400">
						{t('dashboard.accountInformationCard.accountName')}
					</Typography>
					<Typography level="h4">{account?.ownerName}</Typography>
				</Flex>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text">
						{t('dashboard.accountInformationCard.accountNo')}
					</Typography>
					<Typography level="h4">{account?.iban}</Typography>
				</Flex>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text">
						{t('dashboard.accountInformationCard.currency')}
					</Typography>
					<Typography level="h4">{account?.currency}</Typography>
				</Flex>
				<Flex direction={'column'} align={'start'}>
					<Typography level="text">
						{t('dashboard.accountInformationCard.balance')}
					</Typography>
					<Typography level="h4">{account?.availableBalance.toFixed(2)}</Typography>
				</Flex>
			</Flex>
		);
	};

	const StatusMessage = () => {
		return (
			<div style={{ marginTop: '14px' }}>
				{createOrderResponse?.isError ? (
					<Alert type={'error'}>{t('orders.common.orderFailed')}</Alert>
				) : (
					createOrderResponse?.data?.id && (
						<Alert type={'success'}>{t('orders.common.orderCreated')}</Alert>
					)
				)}
				{Object.keys(formState.touchedFields).length === 0 && (
					<>
						{signingStatus === SigningStatus.ERROR && (
							<Alert type={'error'}>{t('orders.common.orderSigningFailed')}</Alert>
						)}
						{signingStatus === SigningStatus.SUCCESS && (
							<Alert type={'success'}>{t('orders.common.orderSigned')}</Alert>
						)}
						{signingStatus === SigningStatus.CANCELED && (
							<Alert type={'warning'}>{t('orders.common.orderSigningCanceled')}</Alert>
						)}
					</>
				)}
			</div>
		);
	};

	const ButtonContent = (account: Account) => {
		return (
			<Flex
				direction={'row'}
				justify={'between'}
				align={'center'}
				style={{
					width: '100%',
					height: '71px',
					marginTop: '14px',
				}}
			>
				<Flex direction={'column'}>
					<Typography level="small-text">
						{t('orders.common.accountHeaders.name')}
					</Typography>
					<Typography level="text">{account?.ownerName}</Typography>
					<Typography level="small-text">
						{t('orders.common.accountHeaders.number')}
					</Typography>
					<Typography level="text">{account?.iban}</Typography>
				</Flex>
				<Flex direction={'column'}>
					<Typography level="small-text">
						{t('orders.common.accountHeaders.currency')}
					</Typography>
					<Typography level="text">{account?.currency}</Typography>
					<Typography level="small-text">
						{t('orders.common.accountHeaders.balance')}
					</Typography>
					<Typography level="text">{account?.availableBalance.toFixed(2)}</Typography>
				</Flex>
			</Flex>
		);
	};

	const AccountBlock = () => {
		if (accounts.isLoading) {
			return (
				<div
					style={{
						display: 'flex',
						marginTop: '28px',
					}}
				>
					<Loader />
				</div>
			);
		}

		return (
			<div className={styles.accountContainer}>
				{!accounts.isError && !accounts.isLoading && fromAccount && (
					<Collapsible
						className={styles.collapsibleOperation}
						type="icon"
						label={toSelf ? t('orders.common.from') : t('orders.common.payer')}
						isOpen={isOpenAccounts}
						setOpen={() => setOpenAccounts(!isOpenAccounts)}
						buttonContent={
							isTabletOrMobile
								? ButtonContent(fromAccount)
								: AccountInformation(fromAccount)
						}
					>
						<ScrollableAccountsList
							accounts={accounts?.data?.items}
							selectedAccount={fromAccount}
							onValueChanged={(e) => {
								const acc = accounts?.data?.items.find((x) => x.id === e);
								setFromAccount(acc);
								setOpenAccounts(false);
							}}
							currentPage={currentPage}
							pageSize={ACCOUNTS_PAGE_SIZE}
							totalCount={accounts?.data?.pagination.count}
							onPageChange={setCurrentPage}
						/>
					</Collapsible>
				)}
			</div>
		);
	};

	const SelfAccountBlock = () => {
		if (!toSelf) return null;

		if (toSelfAccounts?.isLoading) {
			return (
				<div
					style={{
						display: 'flex',
						marginTop: '28px',
					}}
				>
					<Loader />
				</div>
			);
		}

		return (
			<>
				{toSelf && selfAccount && (
					<div className={styles.accountContainer}>
						<Collapsible
							className={styles.collapsibleOperation}
							type="icon"
							label={t('orders.common.to')}
							isOpen={isOpenSelf}
							setOpen={() => setOpenSelf(!isOpenSelf)}
							buttonContent={
								isTabletOrMobile
									? ButtonContent(selfAccount)
									: AccountInformation(selfAccount)
							}
						>
							<ScrollableAccountsList
								accounts={toSelfAccounts?.data?.items}
								selectedAccount={selfAccount}
								disabledAccount={fromAccount}
								onValueChanged={(e) => {
									const acc = toSelfAccounts?.data?.items.find((x) => x.id === e);
									setSelfAccount(acc);
									setOpenSelf(false);
								}}
								currentPage={toSelfCurrentPage}
								pageSize={ACCOUNTS_PAGE_SIZE}
								totalCount={toSelfAccounts?.data?.pagination.count}
								onPageChange={setToSelfCurrentPage}
							/>
						</Collapsible>
					</div>
				)}
			</>
		);
	};

	return (
		<>
			{accounts.isError && <Alert type="error">{t('common.failedFetching')}</Alert>}
			<>
				<form onSubmit={handleSubmit(onSubmit)}>
					<AccountBlock />
					<SelfAccountBlock />
					<Button
						onClick={() => setToSelf(!toSelf)}
						style={{ marginTop: '28px' }}
						variant="link"
						type="button"
						disabled={accounts?.data?.pagination.count < 2}
					>
						{toSelf ? t('orders.common.back') : t('orders.common.toMyAccount')}
					</Button>
					<SimpleInput
						{...register('beneficiary.name', { required: true })}
						disabled={toSelf}
						className={styles.input}
						label={t('orders.newPayment.form.name')}
						isMandatory
						isError={!!errors.beneficiary?.name}
					/>
					{errors.beneficiary?.name && (
						<span className={styles.errorText}>{errors?.beneficiary?.name.message}</span>
					)}
					<div className={styles.inputWithLoaderContainer}>
						<SimpleInput
							{...register('beneficiary.account', {
								required: true,
								onBlur: onIbanBlur,
							})}
							disabled={toSelf}
							className={styles.input}
							label={t('orders.newPayment.form.accountNo')}
							isMandatory
							isError={!!errors.beneficiary?.account}
							// onBlur={onIbanBlur}
						/>
						{validateIBAN.isLoading && (
							<div style={{ marginBottom: '8px' }}>
								<Loader />
							</div>
						)}
					</div>
					{errors.beneficiary?.account && (
						<span className={styles.errorText}>
							{errors?.beneficiary?.account.message}
						</span>
					)}
					<InputDropdown
						inputRegister={register('amount', { required: true })}
						selectorRegister={register('currency', { required: true })}
						className={styles.input}
						label={t('orders.newPayment.form.amount')}
						isMandatory
						options={geoData?.data?.currencies}
						control={control}
						inputMode="numeric"
						isError={!!errors?.amount || !!errors?.currency}
					/>
					{(errors?.amount || errors?.currency) && (
						<span className={styles.errorText}>
							{errors?.amount?.message || errors?.currency?.message}
						</span>
					)}
					<div className={styles.paymentDataContainer}>
						<div className={styles.paymentDataForm}>
							<Typography level="h4">{t('orders.newPayment.data.title')}</Typography>
							{/* <Controller
								name={'payment_urgency'}
								control={control}
								render={({ field }) => {
									const { ref, ...rest } = field; // removes ref
									return (
										<Switch
											{...rest}
											label={t('orders.newPayment.data.urgency.label')}
											className={styles.switch}
											options={[
												{
													value: 'NORMAL',
													label: t('orders.newPayment.data.urgency.normal'),
												},
												{ value: 'FAST', label: t('orders.newPayment.data.urgency.fast') },
											]}
										/>
									);
								}}
							/> */}

							<Switch
								label={t('orders.newPayment.data.transferType.label')}
								className={styles.switch}
								defaultValue={transferInfoType}
								onChange={(e: string) => setTransferInfoType(e)}
								value={transferInfoType}
								options={TRANSFER_INFO_TYPES}
							/>

							{transferInfoType === 'description' ? (
								<>
									<div className={styles.label}>
										<label htmlFor={'paymentDescription'}>
											{t('orders.newPayment.data.transferType.description')}
										</label>
										<div className={styles.mandatory}>*</div>
									</div>
									<TextArea
										{...register('payment_details', { required: true })}
										id={'paymentDescription'}
										radius={'none'}
										resize={'vertical'}
										className={clsx(
											styles.textArea,
											!!errors.payment_details && styles.error,
										)}
									/>
								</>
							) : (
								<SimpleInput
									{...register('payment_details', { required: true })}
									isMandatory
									className={styles.documentNumber}
									label={t('orders.newPayment.data.transferType.refNo')}
									isError={!!errors.payment_details}
								/>
							)}
							{errors.payment_details && (
								<span className={styles.errorText}>
									{errors?.payment_details.message}
								</span>
							)}
							<Controller
								name={'execution_date'}
								control={control}
								rules={{ required: true }}
								render={({ field }) => {
									const { ref, ...rest } = field; // removes ref
									return (
										<CalendarInput
											{...rest}
											isMandatory
											isError={!!errors.execution_date}
											className={styles.date}
											label={t('orders.newPayment.data.date')}
											disabledDays={{ before: today, after: yearAfter() }}
										/>
									);
								}}
							/>
							{errors.execution_date && (
								<span className={styles.errorText}>{errors?.execution_date.message}</span>
							)}

							<div className={styles.inputWithLoaderContainer}>
								<SimpleInput
									{...register('document_number', { required: true })}
									isMandatory
									className={styles.documentNumber}
									label={t('orders.newPayment.data.docNo')}
									isError={!!errors.document_number}
								/>
								{documentNumber.isLoading && <Loader />}
							</div>
							{errors.document_number && (
								<span className={styles.errorText}>{errors.document_number.message}</span>
							)}
						</div>

						<Collapsible
							isOpen={filtersOpen}
							setOpen={setFiltersOpen}
							buttonContent={t('orders.newPayment.additionalData.button')}
						>
							<AdditionalDataForm
								register={register}
								control={control}
								errors={errors}
								watch={watch}
								resetField={resetField}
							/>
						</Collapsible>
						<div>
							{!createOrderResponse?.data?.id && (
								<div style={{ display: 'flex', flexDirection: 'row', columnGap: '16px' }}>
									<Button
										className={clsx(
											styles.createButton,
											isTabletOrMobile && styles.fullWidth,
										)}
										variant="primary"
										type={'submit'}
										disabled={
											!!createOrderResponse?.data?.id || createTransaction.isPending
										}
									>
										{t('orders.common.createButton')}
									</Button>
								</div>
							)}
							<StatusMessage />
						</div>
					</div>
				</form>

				{createOrderResponse?.data?.id && canSign && (
					<div style={{ marginTop: '14px' }}>
						<TFA transactionId={createOrderResponse?.data?.id} onSign={resetForm} />
					</div>
				)}
				<FormBlocker
					when={
						(Object.keys(formState.dirtyFields).length > 0 || operation) &&
						!createOrderResponse?.data
					}
				/>
			</>
		</>
	);
};

export default SepaPaymentForm;
